let Variables = {
  formWarm: false,
  test: 0,
}
declare global {
  interface Window {
    analytics: any
    dataLayer?: any[]
    CookieInformation?: {
      config?: any
      websiteUuid?: string
      cookieConsent?: {
        openSettings?: () => void
      }
      initializeBanner?: () => void
      sendConsent?: () => void
      injectCategoriesConsent?: (e: any, t: any) => void
      changeCategoryConsentDecision?: (e: any, t: any) => void
      submitConsent?: (e: any) => void
      wasBannerShown?: () => boolean
      wasBannerConfirmed?: () => boolean
    }
  }
}

export default Variables
